import React, { useState } from "react";
import { Button, Card, Radio, Space, Table, Typography } from "antd";
import { motion } from "framer-motion";

const { Title } = Typography;

export const QueueCuentas = ({queueHistorical, download, division}) => {
    const [value, setValue] = useState("simple");
    const columns = [
        {
        title: "Campaña (s) seleccionadas",
        dataIndex: "queueName",
        key: "queueName",
        },
    ];
    const variantsCHistorical = {
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: 100 },
    };

    const onChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <motion.div
        className="real-time-container"
        variants={variantsCHistorical}
        initial="hidden"
        animate="visible"
        transition={{
            duration: 1,
        }}
        >
        <div
            className="historical-table-container"
            style={{ width: "100%", height: "50%" }}
        >
            <Table
            dataSource={queueHistorical.length > 0 ? queueHistorical : []}
            columns={columns}
            pagination={false}
            size="middle"
            scroll={{ y: 250 }}
            />
        </div>
        <Card
            style={{
            height: "25vh",
            width: "100%",
            cursor: "default",
            border: "1px solid #01479B",
            marginTop: "1.5rem",
            }}
            bodyStyle={{ width: "100%", padding: "0.5rem" }}
        >
            <div
            className="title-container"
            style={{ height: "10%", paddingLeft: "5rem" }}
            >
            <Title level={4}>Tipo de Exportación: </Title>
            </div>
            <div
            className="format-radio-btns-container"
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10.9rem",
                paddingTop: "2.5rem",
            }}
            >
            Formato:
            <Radio.Group
                onChange={onChange}
                value={value}
                style={{ paddingLeft: "5rem" }}
            >
                <div
                className="radio-group-container"
                style={{ display: "flex", flexDirection: "column" }}
                >
                <Space direction="vertical" style={{ height: "10%" }}>
                    <Radio value="simple">Detalle General</Radio>
                </Space>
                </div>
            </Radio.Group>
            </div>
        </Card>
        <div
            className="dwnld-btn-pagination-container"
            style={{
            width: "100%",
            height: "10%",
            marginTop: "1.5rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            }}
        >
            <Button
            type="primary"
            size="large"
            onClick={() => download(division)}
            >
            Descargar
            </Button>
        </div>
        </motion.div>
    );
}
